import { useGetAllCitiesByStateQuery, GqlState } from 'types/graphql';
import { GraphqlError } from 'src/errors';
import { removeUnsupportedCities } from './utils';

type UseCitiesPageReturn = {
  citiesByState: GqlState[];
  loading: boolean;
};

export const useCitiesPage = (): UseCitiesPageReturn => {
  const { data, loading, error } = useGetAllCitiesByStateQuery();

  if (error) {
    throw new GraphqlError(error);
  }

  // Remove cities for which a city page cannot be created
  removeUnsupportedCities(data);

  const citiesByState = data?.statesWithDispensaries ?? [];

  return {
    citiesByState,
    loading,
  };
};
