import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';

import { GqlState } from 'types/graphql';
import { countryToStateMap } from 'shared/constants/cities-geography';
import { removeAllSpecialCharactersWithoutDash, removeDiacritics } from 'shared/helpers/utils';
import { Link } from 'shared/components/';

type State = {
  country: string;
  name: string;
  cities: GqlState['cities'];
};

type CitiesByStateProps = {
  state: State;
};

type CityLinkProps = {
  cityName: string | null;
  stateName: string;
  country: string;
};

const getCityNameSlug = (cityName: string): string =>
  removeAllSpecialCharactersWithoutDash(removeDiacritics(cityName)).split(' ').join('-').toLowerCase();

const getLinkSlug = (cityName: string, stateName: string): string =>
  `${stateName.toLowerCase()}-${getCityNameSlug(cityName)}`;

const CityLink = ({ cityName, stateName, country }: CityLinkProps): JSX.Element | null => {
  if (!cityName) {
    return null;
  }

  const linkSlug = getLinkSlug(cityName, stateName);

  return (
    <LinkContainer mb='12px' pr='12px' width={[1, 1, 1 / 2, 1 / 4]}>
      <CityPageLink
        data-testid={linkSlug}
        href={`${country.toLowerCase()}/dispensaries/${linkSlug}`}
      >{`${cityName} Dispensaries`}</CityPageLink>
    </LinkContainer>
  );
};

export const CitiesByState = ({ state }: CitiesByStateProps): JSX.Element | null => {
  const stateFullName = countryToStateMap[state.country]?.[state.name] || state.name;
  const cities = state.cities.filter(Boolean);

  return (
    <LinksContainer>
      <StateHeader>{stateFullName}</StateHeader>
      <Flex flexWrap='wrap' mb='32px'>
        {cities.map((city) => (
          <CityLink key={city} cityName={city} stateName={state.name} country={state.country} />
        ))}
      </Flex>
    </LinksContainer>
  );
};

const StateHeader = styled.h2`
  color: #5b676d;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
`;

const LinksContainer = styled.div`
  width: 100%;
`;

const LinkContainer = styled(Box)`
  line-height: 1.5;
`;

const CityPageLink = styled(Link)`
  font-size: 14px;
`;
