import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { Container } from 'shared/components/';
import { mediaQueries, appPadding } from 'shared/styles';

import { CitiesByState } from './components/cities-by-state';
import { useCitiesPage } from './use-cities-page';

const header = 'Browse Dispensaries by City';
const description =
  'See below for a list of some of the best dispensaries in your state that will deliver your favorite cannabis products right to your door. Order the highest quality flower, vaporizers, concentrates, edibles, and topicals from your top local dispensaries.';

export const CitiesPage = (): JSX.Element => {
  const { citiesByState, loading } = useCitiesPage();

  return (
    <PageContainer>
      <Container flexDirection='column'>
        <Flex flexDirection='column' mt={[75]}>
          <MainHeader>{header}</MainHeader>
          <MainCopy>{description}</MainCopy>
        </Flex>
      </Container>
      <CityContainer>
        {!loading &&
          citiesByState.map((state) => {
            const { country, name, cities } = state;

            if (!(typeof country === 'string') || !(typeof name === 'string')) {
              return null;
            }

            return <CitiesByState key={`${country}-${name}`} state={{ country, name, cities }} />;
          })}
      </CityContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  ${appPadding}
  padding-bottom: 45px;
  background-color: #f4f6f7;
  flex: 1;
  @media ${mediaQueries.largePhone} {
    height: auto;
    padding-bottom: 45px;
  }
`;

const MainHeader = styled.h1`
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.v2TextColor2};
  line-height: 1.125;
  margin-top: 0;
`;

const MainCopy = styled.p`
  font-size: 13px;
  color: #6d747b;
  line-height: 22px;
  margin: 0;
  padding: 0;
`;

const CityContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #d7dbde;
  border-radius: 4px;
  padding: 40px;
  margin: 45px auto 0;
  flex-direction: column;
  align-items: flex-start;
`;
