import { CitiesPage } from 'src/cities';
import { MainLayout } from 'components/layouts/main-layout';

import CITIES_QUERY from 'src/cities/cities-query.gql';

import { generateStaticProps } from 'src/utils/ssr';

const getAllCities = async (apolloClient): Promise<void> => {
  try {
    await apolloClient.query({
      query: CITIES_QUERY,
    });
  } catch (e) {
    // do nothing
    // we need the try/catch so the build passes, but don't actually need to do anything here
  }
};

export const getStaticProps = generateStaticProps([getAllCities, true]);

export default Object.assign(CitiesPage, { layout: MainLayout });
