import { GqlState, GqlGetAllCitiesByStateQuery } from 'types/graphql';
import { unsupportedCities } from 'shared/constants/cities-geography';

type GetAllCitiesReturn =
  | GqlGetAllCitiesByStateQuery
  | {
      statesWithDispensaries?: GqlState[];
    }
  | null
  | undefined;

export const removeUnsupportedCities = (data: GetAllCitiesReturn): void => {
  Object.entries(unsupportedCities).forEach(([stateCode, unsupportedCitiesForState]) => {
    const state = data?.statesWithDispensaries?.find((s: GqlState) => s.name === stateCode);

    if (state) {
      unsupportedCitiesForState.forEach((city) => {
        const indexOfUnsupportedCity = state.cities.indexOf(city);

        if (indexOfUnsupportedCity !== -1) {
          state.cities.splice(indexOfUnsupportedCity, 1);
        }
      });
    }
  });
};
